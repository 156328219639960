.form {
    display: flex;
    flex-direction: column;
    width: 40%;
    padding: 50px 30px;
    border: 1px solid #5b8261;
    background: #c6d1c6;
    border-radius: 5px;
}
.form input, select  {
    height: 40px;
    border-radius: 5px;
    border: 1px solid black;
    margin-top: 10px;
}
.form label {
    margin-top: 10px;
    font-size: 20px;
}
.form button {
    margin-top: 37x;
    padding: 12px;
    cursor: pointer;
    font-weight: 600;
    font-size: 15px;
    background-color: #479690;
    color: #000;
    border: none;
}
.form button:hover {
    background-color: #87afad
}
.form span {
    color: red;
    font-family: auto;
    padding-top: 5px;
}
.form p {
    text-align: center;
    margin: 0;
}
.contact-admin {
    padding: 10px;
    font-size: 20px;
}